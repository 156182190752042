import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImg from 'gatsby-background-image'
import { motion } from "framer-motion"
import LogoHd from '../images/logohd.png'
import {  FormularioIconos } from '../components'
import Footer from '../components/Footer'
import FormularioBrokers from '../components/FormularioBrokers'

import NavBar from '../layout/NavBarSlim4'

export default props => {
  const data = useStaticQuery(graphql`
    { fluid: file(relativePath: {eq: "fondo1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1600, quality:90) {
            ...GatsbyImageSharpFluid } } }
      fluidblur: file(relativePath: {eq: "fondo1blur.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1600, quality:90) {
            ...GatsbyImageSharpFluid } } } 
    }
  `)
  return (
    <BackgroundImg fluid={data.fluid.childImageSharp.fluid}
      className="text-montserrat text-white bg-fixed">

      <NavBar />

      <div className="py-8 grid grid-cols-1 lg:grid-cols-2 items-center py-12 lg:py-24 text-gray-700 container m-auto text-white">
            <div className="justify-center">
                <div className="px-6 my-12 m-auto container max-w-3xl text-white">
                  <BackgroundImg fluid={data.fluidblur.childImageSharp.fluid} className="bg-fixed rounded-lg ">
                    <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: .8 }}
                      className="text-left text-montserrat bg-black bg-opacity-50 rounded-lg shadow-lg py-6 mt-6
                          w-full ">
                      <img className="m-auto py-12" width="120px" alt="logo" src={LogoHd} />
                      <h1 className="text-3xl shadow text-center text-quan leading-none font-semibold">Invierte con nosotros</h1>
                      <FormularioIconos />
                      {/* <BotonIcono texto="Enviar"/> */}
                    </motion.div>
                  </BackgroundImg>
                </div>
            </div>
            <div className="text-center"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1400">
                  <div className="px-6 my-12 m-auto container max-w-3xl text-white">
                <BackgroundImg fluid={data.fluidblur.childImageSharp.fluid} className="bg-fixed rounded-lg ">
                  <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: .8 }}
                    className="text-left text-montserrat bg-black bg-opacity-50 rounded-lg shadow-lg py-6 mt-6
                        w-full ">
                    <img className="m-auto py-12" width="120px" alt="logo" src={LogoHd} />
                    <h1 className="text-3xl shadow text-center text-quan leading-none font-semibold">Vende nuestros productos</h1>
                    <FormularioBrokers />
                    {/* <BotonIcono texto="Enviar"/> */}
                  </motion.div>
                </BackgroundImg>
                </div>
            </div>
        </div>
      
        
      <Footer />

    </BackgroundImg>

  )
}