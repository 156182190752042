import React, { useState } from "react";
import iconSend from '../images/iconoSend.png'
import iconPhone from '../images/phone-icon.png'
import iconMail from '../images/mail-icon.png'
import iconName from '../images/iconoNombre.png'

export const FormularioBrokers = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [message, setMessage] = useState("");
    //-------------------------------------------HANDLE SUBMIT ------------------------------------
    const handleSubmitE = (evte) => {

        evte.preventDefault();
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/7338973/539a5776-c975-4495-a3b2-07060018e898'
        var data = {
            "fields": [
                {
                    "name": "email",
                    "value": email
                },
                {
                    "name": "firstname",
                    "value": name
                },
                {
                    "name": "phone",
                    "value": tel
                },
                {
                    "name": "message",
                    "value": message
                }
            ],
            "context": {
                "pageUri": "grupolibera.mx",
                "pageName": "Grupo Libera Formulario de Contacto"
            },
        }
        console.log(data)
        var final_data1 = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                alert(xhr.responseText); // Returns a 200 response if the submission is successful.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.     
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found    
            }
        }
        // Sends the request 
        xhr.send(final_data1)
    }


    //---------------------------------------------------RETURN------------------------------------------
    return (
        <form onSubmit={handleSubmitE}
            className="sm:px-8 py-4 sm:py-8 grid grid-cols-1 bg-black rounded-lg shadow-lg mx-4 my-2 bg-opacity-0">
            {/* <h4>
               Envíanos tu info y nos pondremos en contacto a la brevedad. 
            </h4> */}
            <div className="m-auto grid grid-cols-1 xl:grid-cols-1">
                <label className="px-4 py-2 mx-2 rounded-lg text-sm text-white flex items-center space-x-4 border my-2 border-libera-500
                shadow-lg bg-black bg-opacity-75 border-libera-500 text-center 
                ">
                    
                    <input
                        className="bg-black bg-opacity-75 rounded-lg h-8 border-none focus:outline-none 
                        items-center shadow-lg px-2 mx-2 text-white"
                        type="text"
                        value={name}
                        placeholder="Ingresar Nombre"
                        onChange={e => setName(e.target.value)}
                    />
                </label>
                <label className="px-4 py-2 mx-2 rounded-lg text-sm text-white flex items-center space-x-4 border my-2 border-libera-500
                shadow-lg bg-black bg-opacity-75 border-libera-500 text-center
                ">
                    
                    <input
                        className="bg-black bg-opacity-75 rounded-lg h-8 border-libera-500 focus:outline-none
                        items-center shadow-lg px-2 mx-2 text-white"
                        type="email"
                        value={email}
                        placeholder="Ingresar Email"
                        onChange={e => setEmail(e.target.value)}
                    />
                </label>
                <label className="px-4 py-2 mx-2 rounded-lg text-sm text-white flex items-center space-x-4 border my-2 border-libera-500
                shadow-lg bg-black bg-opacity-75 border-libera-500 text-center
                ">
                    
                    <input
                        className="bg-black bg-opacity-75 rounded-lg h-8 border-libera-500 focus:outline-none
                        items-center shadow-lg px-2 mx-2 text-white"
                        type="text"
                        value={tel}
                        placeholder="Ingresar Teléfono"
                        onChange={e => setTel(e.target.value)}
                    />
                </label>
                <label className="px-4 py-2 mx-2 rounded-lg text-sm text-white flex items-center space-x-4 border my-2 border-libera-500
                shadow-lg bg-black bg-opacity-75 border-libera-500 text-center
                ">
                    <textarea placeholder="Ingresa tu Mensaje" className="bg-black bg-opacity-75 rounded-lg h-8 border-libera-500 focus:outline-none
                        items-center shadow-lg px-2 mx-2 text-white" onChange={e => setMessage(e.target.value)}>{message}</textarea>
                    
                </label>
                
            </div>

            <div className="m-auto col-span-2 pt-12">
                <button className="py-2 px-8 border border-libera-500 bg-libera-600 rounded-lg text-xs flex items-center">
                    <img src={iconSend} alt="icon mail" className="w-6 mr-3" />
                    Enviar
                </button>
            </div>
            {/* <label className="mt-8 px-4 mx-3 grid grid-cols-1 bg-green-800">
                Email
                <input
                    type="text"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </label> */}
        </form>
    )
}

export default FormularioBrokers